.upload-blob-container {
  width: 280px;
  margin: 0 auto;
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px #0000001a;
  align-content: center;
  align-self: center;
  display: flex;
  margin-top: 15px;
}

@media (min-width: 1024px) {
  .upload-blob-container {
    width: 380px;
  }
}

.drag-drop-box {
  border: 2px dashed #dcdcdc;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  cursor: pointer;
  transition:
    border-color 1s,
    background-color 1s;
  margin-bottom: 20px;
  border-radius: 8px;
  align-self: center;
}

.drag-drop-box p {
  margin: 0;
  color: #333;
}

@media (min-width: 1024px) {
  .drag-drop-box {
    padding-inline: 100px;
    padding-block: 50px;
    align-self: center;
  }
}

.drag-active {
  border-color: #0078d4;
  background-color: #eaf4ff;
}

.drag-drop-box:hover {
  border-color: #0078d4;
  background-color: #f0f8ff;
}
.drag-drop-box.disabled {
  cursor: not-allowed;
}

.upload-button {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #0078d4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-file-list {
  margin-top: 20px;
}

.upload-file-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.upload-file-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

@media (min-width: 1024px) {
  .upload-file-list li {
    padding: 15px;
  }
}

.upload-file-list li span {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload-file-list progress {
  flex: 1;
  max-width: 150px;
  margin-left: 10px;
}

/* Confirm button adjustments for larger screens */
.confirm-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.confirm-button:hover {
  background-color: #218838;
}

@media (min-width: 1024px) {
  .confirm-button {
    padding: 10px 16px;
  }
}

.under-label {
  font-size: 0.85rem;
  color: #9a9a9a;
  opacity: 0.75;
}
