@import '../../styles/mixins';

:root {
  --borderBoxShadow:
    0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(153 153 153 / 20%);
}

.roles-guide {
  height: calc(100% - 120px);
  margin: 1.5rem;
}

.roles-table {
  height: 100%;
}

.filter-icon-section {
  margin-right: 1.2rem;
  margin-top: 0.8rem;
}

.roles-table-container {
  height: calc(100% - 60px);
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.MuiTableCell-root {
  font-size: 1rem !important;
}
.MuiTooltip-tooltip {
  font-size: 0.9rem !important;
  color: rgb(90, 90, 90) !important;
  background-color: white !important;
  border: 0.5px solid rgb(150, 150, 150);
}

.MuiTooltip-arrow {
  color: white !important;
  &::before {
    border: 0.5px solid rgb(150, 150, 150);
  }
}

.filterRolesButton {
  background-color: white !important;
  box-shadow:
    0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%),
    0 1px 5px 0 rgb(153 153 153 / 12%);
}

.filterRolesButton:hover {
  cursor: pointer;
  box-shadow: var(--borderBoxShadow);
}

.role-popper-shadow {
  box-shadow: var(--borderBoxShadow);
  z-index: 3;
}
