.sidedrawer-container {
  position: fixed;
  width: 0;
  top: 60px;
  height: 100vh;
  z-index: 2;
  background-color: white;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
  right: 0;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 30px;
}

.sidedrawer-container.open {
  width: 300px;
  padding-bottom: 40px;
}
.sidedrawer-content {
  height: calc(100% - 10px);
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidedrawer-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidedrawer-header {
  margin-left: 10;
}

.mobile-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  /* For smooth scrolling on iOS devices */
  scroll-behavior: smooth;
}

@media (min-width: 1024px) {
  .sidedrawer-container.open {
    width: 400px;
  }
}

// .processing-indicator {
//     position: fixed;
//     top: 50%;
//     right: calc(300px + 20px); // Adjust 300px to the width of your SideDrawer, 20px is the gap
//     transform: translateY(-50%);
//     background-color: #ffffff;
//     padding: 10px 20px;
//     border-radius: 4px;
//     display: flex;
//     align-items: center;
//     z-index: 1000; // Ensure it's above other elements
//     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
// }
