@import '../../styles/mixins';

.navbar {
  min-height: 61px;
  padding: 0 10px;
  margin-bottom: 0;
  border: none;
  font-size: 1rem;
  border-radius: 0;
  background-color: white;
  // background-color: hsla(0, 0%, 100%, 0.96);
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .navbar-brand {
    font-weight: 400;
    margin: 5px 0;
    color: #777;
    margin-left: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem;
    white-space: pre-wrap;
    max-width: 50%;
    width: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &.blue,
  &.dark {
    border-color: rgba(255, 255, 255, 0.1);
    color: white;

    .navbar-brand,
    .logo > a {
      color: white;
    }

    .navbar-brand:focus,
    .navbar-brand:hover {
      color: white;
    }
  }

  &.dark {
    background-color: #292929;
  }

  &.blue {
    background-color: #3897e3;
  }

  .navbar-brand:focus,
  .navbar-brand:hover {
    color: #777;
  }

  .brandLink {
    &:hover {
      cursor: pointer;
      color: #0056b3;
      text-decoration: underline;
    }
  }

  .search {
    padding: 0 1rem;

    .search-container {
      &::before {
        left: 0;
        right: 0;
        bottom: 0;
        content: '\00a0';
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        pointer-events: none;
        border-color: #d2d2d2 !important;
        border-width: 1px !important;
      }

      display: inline-flex;
      position: relative;

      &::after {
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        position: absolute;
        transform: scaleX(0);
        transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        border-bottom: 2px solid #7ba0f4;
        pointer-events: none;
      }

      &.focused::after {
        transform: scaleX(1);
      }

      > .form-control {
        font: inherit;
        color: currentColor;
        width: 100%;
        border: 0;
        height: 1.1875em;
        margin: 0;
        display: block;
        padding: 6px 0 7px;
        min-width: 0;
        background: none;
        box-sizing: content-box;
        -webkit-tap-highlight-color: transparent;
      }
    }

    .btn {
      width: 41px;
      height: 41px;
      font-size: 20px;
      min-width: 41px;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 30px;
      color: #999;
      background-color: #fff;
      border: none;
      cursor: pointer;
      margin: 0.3125rem 10px;
      position: relative;
      box-shadow:
        0 2px 2px 0 rgba(153, 153, 153, 0.14),
        0 3px 1px -2px rgba(153, 153, 153, 0.2),
        0 1px 5px 0 rgba(153, 153, 153, 0.12);
      min-height: auto;
      text-align: center;
      transition:
        box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
        background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      font-weight: 400;
      line-height: 1.42857143;
      white-space: nowrap;
      will-change: box-shadow, transform;
      touch-action: manipulation;
      letter-spacing: 0;
      text-transform: uppercase;
      vertical-align: middle;

      &:hover {
        box-shadow:
          0 14px 26px -12px rgba(153, 153, 153, 0.42),
          0 4px 23px 0px rgba(0, 0, 0, 0.12),
          0 8px 10px -5px rgba(153, 153, 153, 0.2);
      }

      > svg {
        width: 17px;
      }
    }
  }

  @media (max-width: 991px) {
    .navbar-brand {
      font-size: 1.35rem;
      line-height: 1.5rem;
      height: 3.4rem;
    }

    .navbar-toggler {
      display: inherit;
    }

    .nav-bar-links {
      display: none;
    }
  }
  @media (max-width: 600px) {
    .navbar-brand {
      font-size: 1.2rem;
      line-height: 1.25rem;
      height: 2.8rem;
    }
  }

  @media (max-width: 550px) {
    .search {
      padding: 0;
      flex-flow: row;
    }
  }
}

@media (max-width: 991px) {
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 280px;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
  }

  .nav-open {
    .sidebar {
      @include transform-translate-x(0px);
    }

    .navbar .container {
      left: -280px;
    }

    .main-panel {
      left: 0;
      @include transform-translate-x(-280px);
    }
  }
}

@media (max-width: 600px) {
  .search {
    .btn {
      display: none;
    }
  }
}
