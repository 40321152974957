@import '../../../styles/mixins';

.video-player {
  margin-top: auto;
  margin-bottom: auto;
}
.video-player-fullSize {
  width: 70%;
  margin: auto;
}
.replay-button {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
.replay-button:hover {
  text-shadow:
    0em 0em 1em #fff,
    0em 0em 0.5em #fff;
  //box-shadow: 0em 0em 1em #fff, 0em 0em 0.5em #fff;
}
.mute-button:hover {
  text-shadow: none !important;
}
.disable-progress-control {
  pointer-events: none;
}
.disable-progress-control-pointer {
  cursor: not-allowed !important;
}
.hearing-room-overflow {
  margin-right: 1.5rem;
  margin-left: 1rem;
}

.player-wrapper {
  position: relative;
  //margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  //padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

video::cue {
  font-size: 1.5rem;
  font-family: 'Montserrat-Regular';
}
