@import '../../styles/mixins';

.customGrid {
  display: flex;
  height: 100%;
  align-items: center;
}

.multiple-line-overflow {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.positionColumn {
  text-align: center;
  flex-grow: 1;
}

.no-users {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
}
.max-length {
  font-style: italic;
  font-size: 0.8rem;
}
