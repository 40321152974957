@import '../../../styles/mixins';

.sort-column-header {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.sort-column-header .text {
  margin-right: 0.5rem;
  font-weight: 700;
  color: #777;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 auto;
}

.sort-column-header .icons {
  display: flex;
  margin-right: 0.5rem;
  font-weight: 700;
  color: #777;
  flex: 0 0 auto;
}

.sort-column-header .icon {
  margin-right: 0.3rem;
  cursor: pointer;

  &:hover {
    font-size: 1.15rem;
  }
}

.disabled-button {
  opacity: 0.5;
  pointer-events: none;
}

.hover-change {
  .new {
    opacity: 1;
  }

  .old,
  .new {
    position: absolute;
    transition: opacity 0.2s linear;
  }

  .old {
    opacity: 0;
  }
}

.attachmentIcon {
  height: 12px;
  width: 12px;
  color: #0288d1;
}

.highlight-deepSearch {
  background-color: yellow !important;
}

.highlight-filter {
  background-color: #ffd580 !important;
}

.matchesItem {
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}
