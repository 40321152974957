@import '../../styles/mixins';

.common-actions-button {
  .modifier {
    text-transform: none;
    right: 0 !important;
    left: auto !important;
    margin: 0 !important;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2rem 1rem;

    .dropdown-item svg {
      margin-right: 5px;
      width: 17px;
    }
  }
}
